body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker__input-container {
  border-radius: 5px;
  border: solid 1px #c0c0c0;
  padding: 5px;
  width: fit-content;
  cursor: pointer;
  height: 2rem;
}

.business-hours-table .react-datepicker-wrapper,
.business-hours-table .react-datepicker-wrapper * {
  width: 100% !important;
}
