.audio-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .audio-container audio {
    height: 20px;
  }
  
  .audio-container button {
    color: #6E6E6E;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    /* background-color: #e8e6e6; */
    border: none;
    cursor: pointer;
  }

  .button-stop-container {
    position: relative;
    display: inline-block;
    height: 20px;
}

.button-stop-container .button-stop-recording {
    position: relative;
    z-index: 1;
    font-size: 20px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
}

.button-stop-container .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: expand 2s infinite;
    z-index: 0;
}

@keyframes expand {
    0% {
        width: 3px;
        height: 3px;
        opacity: 1;
    }
    100% {
        width: 40px;
        height: 40px;
        opacity: 0;
    }
}